<script setup lang="ts">
const props = defineProps({
  tag: {
    type: Object as PropType<UiTagOption>,
    default: () => {},
  },
})
</script>

<template>
  <li class="ui-tag" :style="props.tag.cssObject">
    {{ props.tag.text }}
  </li>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';
.ui-tag {
  display: inline-block;
  background-color: var(--grey-2);
  color: #fff;
  line-height: 1;
  text-wrap: nowrap;
  padding: 5px 8px 5px;
  border-radius: 4px;
  .fluid(font-size, 10, 12);
  font-weight: 700;
}
</style>
